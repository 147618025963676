import 'core-js/stable';

/////////////////////////////////////////////////////////////
// Make mobile navigation work

const headerEl = document.querySelector('.main-header');
const menubtn = document.querySelector('.btn-mobile-nav');

menubtn.addEventListener('click', function () {
  headerEl.classList.toggle('nav-open');
});

/////////////////////////////////////////////////////////////
// Smooth scrolling animation

// const allLinks = document.querySelectorAll('a:link');
const allLinks = document.querySelectorAll('.internal');

allLinks.forEach(function (link) {
  link.addEventListener('click', function (e) {
    e.preventDefault();
    const href = link.getAttribute('href');

    // Scroll back to top
    if (href === '#')
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });

    // Close mobile navigation
    if (link.classList.contains('main-nav-links'))
      headerEl.classList.toggle('nav-open');

    // Scroll to other links
    if (href !== '#' && href.startsWith('#')) {
      const sectionEl = document.querySelector(href);
      sectionEl.scrollIntoView({ behavior: 'smooth' });
    }
  });
});

/////////////////////////////////////////////////////////////
// Sticky navigation

const sectionHeroEl = document.querySelector('.section-hero');

const obs = new IntersectionObserver(
  function (entries) {
    const ent = entries[0];

    if (!ent.isIntersecting) {
      document.body.classList.add('sticky');
    }

    if (ent.isIntersecting) {
      document.body.classList.remove('sticky');
    }
  },
  {
    // In the viewport
    root: null,
    threshold: 0,
    rootMargin: '-80px',
  }
);
obs.observe(sectionHeroEl);

/////////////////////////////////////////////////////////////
// Copyright year

const year = document.querySelector('.year');
const date = new Date();
year.textContent = date.getFullYear();

/////////////////////////////////////////////////////////////
// Dark Mode

const fullBody = document.body;
const darkBtn = document.querySelector('.dark-mode-btn');

darkBtn.addEventListener('click', function () {
  fullBody.classList.toggle('dark');
});
